import * as React from "react";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import GeneralTab from "../../components/HTabs";
import Document from "../../components/Document";
import About from "../../components/About";
// import Hero from "../../components/Hero";
import Cta from "../../components/Cta";
import Package from "../../components/GSTTemp/Package";

export default function ClosureOfLLp() {
  /* Slider */
  // const ClosureLLPSlider = [
  //   {
  //     header_color: true,
  //     content_color: true,
  //     marginTop: 70,
  //     background: `linear-gradient(139.4deg, #0D324D, #7F5A83)`,
  //     header: `Closure a Limited Liability Partnership`,
  //     content: `Closure a Limited Liability Partnership with TODAYFILINGS Experts`,
  //     image: "/imgs/registration/iec/iec-slider.png",
  //     alt_tag: "Closure a Limited Liability Partnership Service in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Closure Of LLP`,
    buyBtnLink: `#pricing-buy`,
    price: `7999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data Without Form*/
  const ClosureLLPAboutData = {
    title: `Limited Liability Partnership`,
    sub_title: `Closure of limited liability partnership in just 7 days`,
    paragraph: `Whether or not the firm is in operation, an LLP or Limited
      Liability Partnership must file certain annual returns that
      are required. If the company does to submit the required
      returns, the LLP will be subject to fines and prosecution in
      accordance with the LLP Act, and any designated partners or
      directors will also be subject to the same fines and
      prosecution.`,
    header: `Do you wish to dissolve an LLP?`,
    points: [
      `Since the ROC or MCA
        database needs to be updated and the LLP is free form, it is
        necessary to file a Closure of LLP with the ROC. For
        Closing, the Limited Liability Partnership Closure
        Application must be filed.`,
    ],
  };

  /* Document Section Data */
  const ClosureLLPDocData = {
    id: 'closure-llp-doc',
    heading: `Documents required for Limited Liability Partnership`,
    sub_heading: `Documents needed to file the appeal`,
    doc_contents: [
      {
        col: `col-lg-4 col-md-6`,
        delay: '100',
        img: `/imgs/icons/bregistration/pancard.png`,
        alt_tag: `ID Proof of Partners`,
        header: `ID Proof of Partners`,
        description: `At the time of LLP registration, all partners must supply
        their PAN. The PAN card is the main form of identification.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '200',
        img: `/imgs/icons/bregistration/passport.png`,
        alt_tag: `Address Proof of Partners`,
        header: `Address Proof of Partners`,
        description: `Any document, including an Aadhar Card, a passport, a voter
        ID, or a driver's license, may be submitted by the partner.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '300',
        img: `/imgs/icons/bregistration/electric.png`,
        alt_tag: `Residence Proof of Partners`,
        header: `Residence Proof of Partners`,
        description: `As a residential evidence, the most recent bank statement,
        phone bill, mobile bill, energy bill, or gas bill should be
        provided.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '400',
        img: `/imgs/icons/bregistration/signature.png`,
        alt_tag: `Digital Signature Certificate`,
        header: `Digital Signature Certificate`,
        description: `Since the authorized signatory will be digitally signing all
        documents and applications,of the digital signature
        certificate.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '500',
        img: `/imgs/icons/bregistration/bill.png`,
        alt_tag: `Utility Bill`,
        header: `Utility Bills`,
        description: `A utility bill is a statement of the amount owed for
        essential services for the LLP's intended registered office.`,
      },
      {
        col: `col-lg-4 col-md-6`,
        delay: '600',
        img: `/imgs/icons/bregistration/rental.png`,
        alt_tag: `Rental Agreement`,
        header: `Rental Agreement`,
        description: `Copy of the lease agreement contract between the owner of a
        property (the landlord) and the tenant who takes it on rent.`,
      },
    ],
  };
  /* FaQ Data */
  var ClosureLLPFAQ = [
    {
      header: 'When must my Limited Liability Partnership be finished?',
      body: [
        {
          content: `When the Limited Liability Partnership has been inactive or
          inoperative for at least a year following the date of incorporation
          or registration and when it has no assets, properties, or
          liabilities as of the date of practical application.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: 'What is the Limited Liability Partnership strike-off procedure?',
      body: [
        {
          content: `In the event that the Limited Liability Partnership wishes to
          cease operations or stops conducting any commercial activity for a
          period of at least one year. With the consent of all partners, the
          register may revoke the designation of Limited Liability
          Partnership by utilizing the electronic form 24 (e-Form 24).`,
          icon: false,
        },
      ],
    },
    {
      header: `What prerequisites must be met in order to strike off a failed LLP?`,
      body: [
        {
          content: `A PAN is required, and the Limited Liability Partnership must be
          dormant or non-operational for at least a year after
          incorporation. At the time of incorporation, a live bank account
          should not exist. The LLP ought to have turned in the most recent
          IT returns.`,
          icon: false,
        },
      ],
    },
    // 3
    {
      header: 'What does the closure checklist entail?',
      body: [
        {
          content: `A minimum of one year must have passed after the LLP was
          incorporated before submitting an application to have its name
          removed.`,
          icon: false,
        },
      ],
    },
    {
      header: 'In case of closure of LLP, who has to sign the application?',
      body: [
        {
          content: `In case of closure of LLP, the designated partners have to sign the application 
          for the closure of LLP along with the consent of other partners.`,
          icon: false,
        },
      ],
    },
    {
      header:
        'If there is a need for striking off a by a defunct LLP, what are conditions need to be met?',
      body: [
        {
          content: `If the LLP fulfills the following condition, it can file an application for striking off
           its names from the register of LLP:`,
          icon: false,
        },
        {
          content: `The LLP must have a PAN.`,
          icon: true,
        },
        {
          content: `The LLP should be inactive for a period of at least one year or non-operational 
          from the time of incorporation.`,
          icon: true,
        },
        {
          content: `There should be no live bank account at the time of application.`,
          icon: true,
        },
        {
          content: `The LLP should have filed the latest IT returns.`,
          icon: true,
        },
      ],
    },
    {
      header: 'What documents required to close LLP?',
      body: [
        {
          content: `A statement of accounts revealing NIL assets & NIL liabilities, made up to a date not earlier 
          than thirty days of the date of filing of Form 24 certified by a Chartered Accountant in practice. 
          Copy of acknowledgement of latest ITR- Self Explanatory.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can we close LLP without annual filing?',
      body: [
        {
          content: `LLP. The LLP Rules, 2008, Rule 37(1)(b), contains the statutory provisions for striking out
           an LLP. According to the guidelines, there is no exception for filing the e-form (LLP-8 and LLP-11) for
            LLP strike off. Before an LLP may be struck out, Every LLP must complete annual filing before strike off 
            of LLP.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What is the process of leaving a LLP?',
      body: [
        {
          content: `In the absence of an agreement, a partner can resign by intimating the other partners with a 
          notice. Such a notice must be issued 30 days prior to the date of resignation. Resignation from a LLP will
           not automatically discharge the liabilities of the Partner with respect to the LLP.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is audit report mandatory for LLP?',
      body: [
        {
          content: `The accounts of every LLP shall be audited in accordance with Rule 24 of LLP, Rules 2009. 
          Such rules, inter-alia, provides that any LLP, whose turnover does not exceed, in any financial year,
           forty lakh rupees, or whose contribution does not exceed twenty five lakh rupees, is not required to get 
           its accounts audited`,
          icon: false,
        },
      ],
    },
  ];

  /* Htabs */
  const ClosureLLPData = {
    id: 'features',
    heading: 'Registration Procedure',
    paragraph: '',
    body: [
      {
        tab_title: 'Winding up Procedure',
        tab_content: [
          {
            content_title: 'The Detailed Procedure: Winding up of the LLP',
            content_paragraph: ` According to the legislation, registered entities like LLP
          must be dissolved or wound up through a proper process.`,
            points: [],
          },
        ],
        content_img: '/imgs/assited/virtual-accounting.png',
        alt_img: 'TODAYFILINGS-winding Procedure',
      },
      {
        tab_title: 'Winding up Process',
        tab_content: [
          {
            content_title: 'Types of LLP Winding up process',
            content_paragraph: `The following options are available for terminating a
          Limited Liability Partnership:`,
            points: [
              `Declaring LLP as Inactive.`,
              `Declaring LLP as Dissolving LLP.`,
            ],
          },
        ],
        content_img: '/imgs/assited/professional-accounting.png',
        alt_img: 'TODAYFILINGS-LLP winding Procedure',
      },
      {
        tab_title: 'Voluntary Winding-up',
        tab_content: [
          {
            content_title: `Voluntary Winding-up of LLP`,
            content_paragraph: [],
            points: [
              `According to this procedure, the directors or partners
            have chosen to halt, wind up, and close an LLP.`,
              `The resolution must be approved by 3/4 of the entity's members
            or partners in order for the procedure to begin. Within 30
            days of the Resolution's approval, a copy of the
            Resolution must be submitted with the Registrar in Form 1
            and a copy of the authorization report must be delivered
            to the authority designated to handle the LLP's wind-up
            procedure.`,
            ],
          },
        ],
        content_img: '/imgs/assited/invoice-billing.png',
        alt_img: 'TODAYFILINGS-Voluntray winding Procedure',
      },
      {
        tab_title: 'Compulsory Winding-up',
        tab_content: [
          {
            content_title: 'Compulsory Winding-up of LLP',
            content_paragraph: `The court may order the winding up and dissolution of an
          LLP for the following reasons through the compulsory
          winding up of LLP process:`,
            points: [
              `If an LLP determines the Tribunal should dissolve it.`,
              `If the firm's number of partners falls to 1 or below 2 for a 
            period of at least six months.`,
              `If a Limited Partnership is unable to pay its debts and loans back.`,
              `If a Limited Liability Partnership has violated the security of the
            state or the laws governing the sovereignty and integrity
            of India.`,
              `If a Limited Liability Partnership has neglected to file a report, a
            statement of account and solvency, or an annual return
            with the registrar for any five consecutive financial
            years.`,
            ],
          },
        ],
        content_img: '/imgs/assited/customized-consulting.png',
        alt_img: 'TODAYFILINGS-Compulsory winding up',
      },
    ],
  };
  return (
    <div>
      <Seo
        title='Closure Of LLP'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={ClosureLLPSlider} /> */}
          <Package packageData={PackageCompData} />

          <About about_data={ClosureLLPAboutData} />

          <Counter />

          <Document docSecData={ClosureLLPDocData} />

          <GeneralTab HTabsData={ClosureLLPData} />
          <Cta />
          <FAQAccord items={ClosureLLPFAQ} />
        </main>
      </Layout>
    </div>
  );
}
